
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient, ExperienceClient, PremiumClient } from '@/services/Services';
import ExperienceResume from '../components/experience/experienceResume.vue';
import ExperienceApplicant from '../components/experience/experienceApplicant.vue';
import ExperienceApplicantManagementModal from '../modals/experienceApplicantManagementModal.vue';
import ProfileImagesAndDescriptionModal from '../modals/profileImagesAndDescriptionModal.vue';
import AnswerToApplicantModal from '../modals/experience/answerToApplicantModal.vue';
import bodymovin, { AnimationItem } from 'lottie-web';
import * as OM from '@/Model';
import SecondChanceModal from '../modals/premium/secondChanceModal.vue';
import store from '@/store';
import { experienceSecondChanceCallback } from '@/afterPremiumActionCallbacks';

@Options({
    components: {
        ExperienceResume,
        ExperienceApplicant
    }
})
export default class MyExperienceDetail extends Vue {

    experienceIdentifier: string = "";
    model: OM.MyExperienceDetailVM = new OM.MyExperienceDetailVM();
    selectedTab: string = "affini";
    tabChangedByPremiumCallback: boolean = false;
    isOpen: boolean = true;
    isUserPremium: boolean = false;
    experienceAnimation: AnimationItem = null;
    isAnimationPlaying = false;

    created() {
        store.state.experienceOpenSecondChanceCb = this.experienceOpenSecondChanceCb;
        this.experienceIdentifier = this.$route.params.id ? this.$route.params.id.toString() : "";
        this.init();
    }

    experienceOpenSecondChanceCb(applicant: OM.ApplicantVM){
        this.tabChangedByPremiumCallback = true;
        this.selectedTab = "notAffini";
        this.openProfileDetail(applicant);
    }

    init() {
        Promise.all([
            ExperienceClient.getMyExperienceById(this.experienceIdentifier),
            PremiumClient.checkIfUserIsPremium()
        ])
        .then(xs => {
            this.model = xs[0];
            this.isUserPremium = xs[1];

            if(this.model.applicants.length > 0) {
                if(this.affini.length == 0 && this.notAffini.length > 0 && !this.tabChangedByPremiumCallback)
                    this.selectedTab = "notAffini";
            }
            
            this.checkLoopAnimation();
        })
    }

    editExperience() {
        ExperienceClient.backToDraft(this.model.experienceIdentifier)
        .then(x => {
            this.$router.push('/experience/edit/' + this.model.experienceIdentifier);
        })
    }

    checkLoopAnimation() {
        if(this.model.applicants.length > 0 && !this.experienceAnimation) {
            setTimeout(() => {
                this.experienceAnimation = bodymovin.loadAnimation({
                    container: <any>this.$refs.experienceLoop,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: './json/experience_loop.json',
                })

                this.experienceAnimation.play();
                this.isAnimationPlaying = true;
            }, 300);
        }
        else if(this.model.applicants.length > 0 && !this.isAnimationPlaying) {
            this.experienceAnimation.play();
            this.isAnimationPlaying = true;
        } else if(this.model.applicants.length == 0 && this.isAnimationPlaying){
            this.experienceAnimation.stop();
            this.isAnimationPlaying = false;
        }
    }

    isAffine(item: OM.ApplicantVM){
        return item.affinityPercentage >= this.model.minimumAffinityPercentage;
    }

    isNotAffine(item: OM.ApplicantVM){
        return item.affinityPercentage < this.model.minimumAffinityPercentage;
    }

    get affini() {
        if(this.model.applicants.length == 0)
            return;

        return this.model.applicants.filter(x => this.isAffine(x));
    }
    get notAffini() {
        if(this.model.applicants.length == 0)
            return;

        return this.model.applicants.filter(x => this.isNotAffine(x));
    }

    openProfileDetail(applicant: OM.ApplicantVM) {
        if(this.model.isExpired)
            return;
            
        var matchCompleted = applicant.matchCompleted;
        var isSecondChance = this.isNotAffine(applicant);
        var chatRoomIdentifier = applicant.chatRoomIdentifier;
 
        if(chatRoomIdentifier)
            this.$router.push('/chats/' + applicant.chatRoomIdentifier);
        else if(!matchCompleted && isSecondChance){
            this.$opModal.show(SecondChanceModal, {
                isPremium: this.isUserPremium,
                isExperience: true,
                experienceIdentifier: this.model.experienceIdentifier,
                secondChanceId: applicant.affinityIdentifier,
                acceptUrl: '/new-experience-match/' + applicant.affinityIdentifier + "/" + this.model.experienceIdentifier,
                afterPremiumAction: experienceSecondChanceCallback(applicant.affinityIdentifier, this.model.experienceIdentifier),
                refreshCallback: () => {
                    this.init();
                }
            })
        }
        else if(!matchCompleted && !isSecondChance){
            this.$opModal.show(AnswerToApplicantModal, {
                affinityIdentifier: applicant.affinityIdentifier,
                callback: () => {
                    this.init();
                }
            })
        }

        // this.$opModal.show(ExperienceApplicantManagementModal, {
        //     hasToAnswer: !applicant.matchCompleted,
        //     chatIdentifier: applicant.chatRoomIdentifier,
        //     senderProfileName: applicant.appUserName,
        //     profileCb: () => {
        //         if(applicant.matchCompleted) {
        //             this.$opModal.closeLast();
        //             this.$opModal.show(ProfileImagesAndDescriptionModal, {
        //                 userIdentifier: applicant.appUserIdentifier
        //             })
        //         }
        //     },
        //     chatCb: () => {
        //         if(applicant.chatRoomIdentifier) {
        //             this.$opModal.closeLast();
        //             this.$router.push('/chats/' + applicant.chatRoomIdentifier);
        //         }
        //     },
        //     answerToOther: () => {
        //         this.$opModal.closeLast();
        //         PremiumClient.checkIfUserIsPremium()
        //         .then(x => {
        //             var canReply = x;
        //             if(applicant.affinityPercentage >= this.model.minimumAffinityPercentage)
        //                 canReply = true;

        //             this.$opModal.show(AnswerToApplicantModal, {
        //                 affinityIdentifier: applicant.affinityIdentifier,
        //                 isPremium: canReply,
        //                 callback: () => {
        //                     this.init();
        //                 }
        //             })
        //         })
        //     }
        // })
    }

    unmounted(){
        store.state.experienceOpenSecondChanceCb = null;
    }
}

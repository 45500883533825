
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { ExperienceAffinityClient, SecondChanceMatchClient } from '@/services/Services';
import PersonInfo from '@/components/personInfo.vue';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import ReportQuestion from '@/components/reportQuestion.vue';
import ViewDescriptionModal from '../viewDescriptionModal.vue';
import * as OM from '@/Model';
import { StorageServices } from '@/services/StorageServices';
import NextFinderInfoModal from '../nextFinderInfoModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import PreviewOtherPofileModal from '../previewOtherPofileModal.vue';

@Options({
    components: {
        PersonInfo,
        LoveFaceShadowBar,
        ReportQuestion
    }
})
export default class AnswerToApplicantModal extends Vue {

    @Prop() affinityIdentifier : string;
    @Prop() callback : any;

    affinityDetail: OM.ExperienceAffinityDetailVM = new OM.ExperienceAffinityDetailVM();
    loaded = false;
    error = false;

    created() {
        ExperienceAffinityClient.getMyApplicationDetailByAffinityId(this.affinityIdentifier)
        .then(x => {
            this.affinityDetail = x;
        }).catch( err => {
            this.error = true;
        }).finally( () => {
            this.loaded = true;
        })
    }

    get affinityRateText(){
        return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}", "Affinity Rate: {{rate}}%", "", "", { rate: this.affinityDetail.match.minimumAffinityRate });   
    }

    openDescription(){
        if(!this.affinityDetail.match.biography)
            return;

        this.$opModal.show(ViewDescriptionModal, {
            ownDescription: false,
            completeName: this.affinityDetail.match.personName,
            birthData: this.affinityDetail.match.birthDate,
            meters: this.affinityDetail.match.distanceMeters,
            sexualOrientation: this.affinityDetail.match.sexualOrientation,
            description: this.affinityDetail.match.biography
        })
    }

    refuseMatch(){
        var dontNextShowMessage = StorageServices.getDontShowNextProfileMessage();

        if(dontNextShowMessage){
            this.refuseAffinityAndGoNext();
            return;
        }
        
        this.$opModal.show(NextFinderInfoModal, {
            nextProfile: (dontShowAnymore: boolean) => {
                StorageServices.setDontShowNextProfileMessage(dontShowAnymore);
                this.$opModal.closeLast();
                this.refuseAffinityAndGoNext();
            },
            stay: (dontShowAnymore: boolean) => {
                StorageServices.setDontShowNextProfileMessage(dontShowAnymore);
                this.$opModal.closeLast();
            }
        })
    }

    refuseAffinityAndGoNext(){
        ExperienceAffinityClient.refuseAffinity(this.affinityDetail.match.matchIdentifier)
        .then(x => {
            this.$opModal.closeLast();
            this.callback();
        })
    }

    createChat() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UnaVoltaAccettatoSiCreeràDirettamenteLaChatConQuestoProfilo.<br/>Vuoiprocedere?", "Una volta accettato si creerà direttamente la chat con questo profilo. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_si", "Si"),
            confirmCb: () => {

                var confirmAffinityVM: OM.ExperienceConfirmAffinityVM = {
                    affinityIdentifier: this.affinityDetail.match.matchIdentifier,
                    experienceIdentifier: this.affinityDetail.experience.experienceIdentifier
                }

                ExperienceAffinityClient.confirmExperienceAffinity(confirmAffinityVM)
                .then(x => {
                    this.$opModal.closeAll()
                    this.affinityDetail.match.chatRoomIdentifier = x;
                    this.$router.replace('/chats/' + this.affinityDetail.match.chatRoomIdentifier);
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: 'face_error.svg',
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        },
                    })
                })
            },
            deny: this.$localizationService.getLocalizedValue("app_no", "No"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })

    }

    viewQuestionAndAnswers(){
        this.$opModal.show(PreviewOtherPofileModal, {
            match: this.affinityDetail.match,
            replyCallback: () => {
                this.$opModal.closeAll();
                this.replyMatch();
            }
        })
    }

    replyMatch() {
        this.$router.push('/new-experience-match/' + this.affinityDetail.match.matchIdentifier + '/' + this.affinityDetail.experience.experienceIdentifier);
    }
}
